@import '../theme.scss';

.passwordInput {
  &.invalid {
    input,
    textarea {
      border-color: $color-red;
    }
  }
}
